<template>
  <div class="navbar_wrapper">
    <div class="teknisk_industrivern_navbar">
      <img
          :src="require('@/assets/images/TIV_nettkurs_logo.png')"
          alt="TIV_nettkurs_logo"
          class="teknisk_industrivern_navbar_logo"
      />

      <div
          class="lg:flex lg:flex-1 lg:items-center lg:justify-end lg:mx-12 overflow-hidden nav-menu"
          id="nav-menu"
          :class="extendHamburgerMenu ? 'hamburger_menu_open' : ''"
      >
        <ui-role
            v-for="item in items"
            :key="item.route"
            :role="item.role"
            :accessRoles="item.accessRoles"
            class="app-header-item-section"
        >
          <app-header-item
              :route="item.route"
              :exact="item.exact"
              @click="() => {showMenu = false; extendHamburgerMenu = false}"
              class="app-header-item"
          >
            {{ $tc(`models.${item.label}`, 2) }}
          </app-header-item>
        </ui-role>

        <auth-checker :access-roles="['SuperAdmin', 'InstructorAdmin', 'Instructor']">
          <span class="ui_role_span app-header-item-section">
            <app-header-item
                route="courseevent-index"
                :exact="false"
                @click="() => {showMenu = false; extendHamburgerMenu = false}"
                class="app-header-item ui_role_span"
            >
              {{ $tc(`models.event`, 2) }}
            </app-header-item>
          </span>
        </auth-checker>
      </div>
      <div class="nav_menu_hamburger_wrapper">
        <div class="nav_menu_hamburger" @click="toggleHamburgerMenu">
          <div class="nav_menu_hamburger_line"/>
          <div class="nav_menu_hamburger_line"/>
          <div class="nav_menu_hamburger_line"/>
        </div>
      </div>
    </div>
    <div class="teknisk_industrivern_sub_navbar">
      <p class="teknisk_industrivern_sub_navbar_text">
        - Brannteknisk kompetansesenter
      </p>
      <div class="teknisk_industrivern_sub_navbar_options">
        <div class="teknisk_industrivern_sub_navbar_option">
          <app-header-locale></app-header-locale>
        </div>
        <div class="teknisk_industrivern_sub_navbar_option">
          <app-header-user></app-header-user>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeaderLocale from '@/components/app/AppHeaderLocale';
import AppHeaderUser from '@/components/app/AppHeaderUser';
import UiRole from '@/components/ui/UiRole';
import AppHeaderItem from './AppHeaderItem';
import AuthChecker from '../../app/auth/components/AuthChecker';

export default {
  name: 'AppNavbar',
  data() {
    return {
      showMenu: true,
      items: [
        {route: 'my-courses-index', label: 'my-courses', role: 'Student'},
        {route: 'courses-index', label: 'course', role: 'SuperAdmin'},
        {route: 'companies-index', label: 'company', role: 'CompanyAdmin'},
        {route: 'buildings-index', label: 'building', role: 'BuildingAdmin'},
        {
          route: 'departments-index',
          label: 'department',
          role: 'DepartmentAdmin'
        },
        {route: "resources-index", label: "resources", role: "DepartmentAdmin"},
        {route: 'users-index', label: 'user', role: 'SuperAdmin'}, //user administration via company, building and department instead
        {route: 'admin-index', label: 'admin', role: 'SuperAdmin'},
      ],
      environment: process.env.NODE_ENV,
      extendHamburgerMenu: false
    };
  },
  components: {
    AuthChecker,
    AppHeaderItem,
    AppHeaderUser,
    AppHeaderLocale,
    UiRole
  },

  methods: {
    toggleHamburgerMenu() {
      this.extendHamburgerMenu = !this.extendHamburgerMenu;
    }
  }
};
</script>

<style scoped>
.navbar_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.teknisk_industrivern_navbar {
  width: 100%;
  height: 7em;
  background-image: linear-gradient(to bottom, #E00010, #B60000);
  display: flex;
}

.teknisk_industrivern_navbar_logo {
  display: flex;
  justify-self: flex-start;
  height: 100%;
  margin-left: 7em;
}

.teknisk_industrivern_sub_navbar {
  display: flex;
  flex-direction: row;
  height: 3em !important;
  width: 100%;
}

.teknisk_industrivern_sub_navbar_text {
  display: flex;
  align-self: center;
  margin-left: 8em;
  justify-self: flex-start;
}

.teknisk_industrivern_sub_navbar_options {
  display: flex;
  margin-left: auto;
  margin-right: 8em;
  height: 100%;
}

.teknisk_industrivern_sub_navbar_option {
  border-right: 0.1em rgba(210, 210, 210, 0.5) solid;
  border-left: 0.1em rgba(210, 210, 210, 0.5) solid;
  width: 11em;
}

.nav_menu_hamburger_wrapper {
  display: none;
  width: 100%;
  justify-content: flex-end;
  margin-right: 1em;
}

.nav_menu_hamburger {
  display: flex;
  border: 1px solid white;
  width: 4em;
  height: 3em;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 2px;
}

.nav_menu_hamburger_line {
  display: flex;
  height: 3px;
  width: 40%;
  justify-content: center;
  align-self: center;
  background-color: white;
  margin-top: 3px;
  margin-bottom: 3px;
  flex-direction: row;
  border-radius: 2px;
}


@media only screen and (max-width: 1023px) {
  .nav-menu {
    display: none;
    width: 100%;
    flex-direction: column;
    background-color: #e20011;
    position: absolute;
    margin-top: 10em;

  }

  .nav_menu_hamburger_wrapper {
    display: flex;
  }

  .hamburger_menu_open {
    display: flex;
  }

  .app-header-item-section {
  }

  .app-header-item {
    width: 100% !important;
    display: flex;
    justify-content: flex-start !important;
    align-items: center !important;
    align-content: center !important;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
    margin-right: 0 !important;
  }

  .app-header-item:hover {
    background-color: #b7000e;
  }

  .nav-item-text {
    margin-left: 1em;
  }

  .teknisk_industrivern_sub_navbar_text {
    display: none;
  }

  .teknisk_industrivern_navbar_logo {
    margin-left: 0;
  }

}
</style>
