<template>
  <app-header-dropdown
    :heading="$t('profile.profile')"
    :items="items"
    class="app_header_dropdown"
  >
  </app-header-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppHeaderDropdown from '@/components/app/AppHeaderDropdown';

export default {
  components: {
    AppHeaderDropdown
  },

  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated', 'oidcUser']),

    items: function() {
      return [
        {
          label: this.$t('labels.profile.my_profile'),
          method: () => {
            if (this.$route.path !== '/no/app/profile/') {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.$router.push({ name: 'profile-edit' });
            }
          }
        },
        {
          label: this.$t('auth.logout'),
          method: () => {
            this.signOutOidc();
          }
        }
      ];
    }
  },

  methods: {
    ...mapActions('oidcStore', ['signOutOidc'])
  }
};
</script>

<style>
.app_header_dropdown {
  display: flex;
  justify-content: center;
}
</style>
