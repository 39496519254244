<template>
  <div>
    <div class="app_top_image" v-bind:style="{backgroundImage: `url(${background})`}">
      <div class="company_logo"><img v-bind:src=logo alt=""></div>
    </div>
  </div>
</template>

<script>
import {getLogoPath} from "@/app/companies/api";
import {getBackgroundPath} from "@/app/buildings/api";

export default {
  name: 'AppBackgroundImage',

  data() {
    return {
      background: null,
      logo: null
    }
  },

  methods: {
    async getBuildingBackground() {
      this.background = await getBackgroundPath();
    },

    async getCompanyLogo() {
      const logoPath = await getLogoPath();
      if (logoPath !== "") {
        this.logo = logoPath;
      }
    },

    imageExists(url) {
      let img = new Image();
      img.src = url;
      return img.height !== 0;
    }

  },

  async mounted() {
    await this.getBuildingBackground();
    await this.getCompanyLogo();
  }

}
</script>

<style scoped>
.app_top_image {
  display: flex;
  height: 15em;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50%;
  justify-content: flex-start;
}

.company_logo {
  display: flex;
  position: absolute;
  max-width: 30em;
  max-height: 15em;
  background-repeat: no-repeat;
  background-size: cover;
  align-self: center;
  margin-left: 10em;
}
</style>
